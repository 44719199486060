<template>
  <div class="staff">
    <div class="left-top">
      <span style="font-size: 18px; font-weight: 400; color: #333"
        >分类列表</span
      >
      <el-divider></el-divider>
      <el-button
        type="primary"
        @click="addPage"
        style="float: left; margin: 10px 5px; padding: 15px 20px"
      >
        <i class="el-icon-circle-plus-outline" style="margin-right: 5px"></i
        >新增分类
      </el-button>
      <el-form
        :model="searchForm"
        :inline="true"
        ref="searchForm"
        class="searchForm"
      >
        <el-form-item label="分类名称:" prop="topicCategoryName">
          <el-input
            style="width: 200px"
            v-model="searchForm.topicCategoryName"
            placeholder="输入分类名称搜索"
          ></el-input>
        </el-form-item>
        <!-- <el-form-item label="创建时间:" prop="createTime">
              <el-date-picker
                style="width: 360px"
               @change="selectTime"
                v-model="releasetime"
                type="daterange"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </el-form-item> -->
        <el-form-item label="所属分类:">
          <el-select
            v-model="choosetopic"
            style="width: 180px"
            @change="Topicselect"
          >
            <el-option
              v-for="item in Alltopic"
              :key="item.value"
              :value="item.value"
              :label="item.label"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="keysSearch(1)">
            <i class="el-icon-search" style="margin-right: 5px"></i>
            搜索
          </el-button>
          <el-button @click="resetForm()">
            <i class="el-icon-refresh-right" style="margin-right: 5px"></i>
            重置
          </el-button>
        </el-form-item>
      </el-form>
    </div>
    <el-table border :data="PageConfigure" row-key="index" style="width: 100%">
      <el-table-column
        fixed
        label="序号"
        width="80"
        type="index"
        align="center"
      ></el-table-column>
      <el-table-column
        fixed
        label="分类名称"
        prop="HelpCenterCategoryName"
        width="150"
        align="center"
      >
      </el-table-column>
      <el-table-column
        label="所属分类"
        width="150"
        prop="ParentCategoryName"
        align="center"
      >
        <template slot-scope="scope">
          <span v-show="scope.row.ParentCategoryName">{{
            scope.row.ParentCategoryName
          }}</span>
          <span v-show="!scope.row.ParentCategoryName">-</span>
        </template>
      </el-table-column>
      <el-table-column
        label="分类排序"
        width="80"
        prop="SortNumber"
        align="center"
      ></el-table-column>
      <el-table-column label="分类描述" prop="Description" align="center">
        <template slot-scope="scope">
          <span v-html="scope.row.Description"></span>
        </template>
      </el-table-column>
      <el-table-column label="图标" width="80" prop="AppImg" align="center">
        <template slot-scope="scope">
          <img
            v-show="scope.row.Img"
            style="width: 40px; height: 40px; background-size: cover"
            :src="scope.row.Img"
            alt=""
          />
          <span v-show="!scope.row.Img">-</span>
        </template>
      </el-table-column>
      <el-table-column
        label="创建时间"
        width="180"
        prop="CreateTime"
        align="center"
      ></el-table-column>
      <el-table-column label="状态" width="100" align="center">
        <template slot-scope="scope">
          <span v-show="scope.row.IsEnable">已启用</span>
          <span v-show="!scope.row.IsEnable" style="color: #cc0000"
            >已禁用</span
          >
        </template>
      </el-table-column>
      <el-table-column fixed="right" width="200" label="操作" align="center">
        <template slot-scope="scope">
          <span
            class="backSpan"
            @click="eduit(scope.row)"
            style="color: #409eff"
            >编辑</span
          >
          <!-- <router-link
              :to="{ name: 'TopicManageList', query: { TopicCategoryId: scope.row.TopicCategoryId }}" @click.native="refresh">
              <span class="backSpan" style="color: #5fb878">配置</span>
            </router-link> -->
          <span
            class="backSpan"
            v-show="scope.row.IsEnable"
            @click="changeStatus(scope.row, 1)"
            style="color: #5fbb78"
            >禁用</span
          >
          <span
            v-show="!scope.row.IsEnable"
            class="backSpan"
            @click="changeStatus(scope.row, 2)"
            style="color: #5fbb78"
            >启用</span
          >
          <span
            class="backSpan"
            @click="PreDelet(scope.row)"
            style="color: #cc0000"
            >删除</span
          >
        </template>
      </el-table-column>
    </el-table>
    <div class="task-list-page" v-if="PageConfigure !== ''">
      <el-pagination
        @current-change="OnCurrentPageChanged"
        @size-change="handleSizeChange"
        :current-page="Params.PageIndex"
        :page-sizes="[10, 20, 30, 40, 50, 60]"
        layout="prev, pager, next,sizes,jumper,total"
        :page-size="Params.PageSize"
        background
        :total="Params.TotalCount"
      >
      </el-pagination>
    </div>
    <!-- 编辑 -->
    <div class="formdia">
      <el-dialog
        :visible.sync="dialogFormVisible"
        custom-class="edit-form"
        :title="dialongtitle"
        :modal-append-to-body="false"
      >
        <el-form :model="Rowcontent" :rules="rules" ref="editForm">
          <el-form-item
            label="分类名称:"
            :label-width="formLabelWidth"
            prop="CategoryName"
          >
            <el-input
              style="width: 340px"
              v-model="Rowcontent.CategoryName"
              placeholder="请输入分类名称"
              maxlength="10"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="分类描述:"
            :label-width="formLabelWidth"
            prop="description"
          >
            <el-input
              style="width: 340px"
              type="textarea"
              :rows="3"
              maxlength="100"
              v-model="Rowcontent.Description"
              placeholder="请输入话题描述"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="所属分类"
            :label-width="formLabelWidth"
            prop="parentTopicCategoryId"
          >
            <el-select
              style="width: 340px"
              v-model="typeValue"
              @change="seltype"
              filterable
              placeholder="请选择分类"
            >
              <el-option
                v-for="item in Alltype"
                :key="item.id"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="分类排序:"
            type="number"
            :label-width="formLabelWidth"
            prop="SortNumber"
          >
             
            <el-input
              style="width: 340px"
              v-model="Rowcontent.SortNumber"
              placeholder="请输入序号"
              maxlength="40"
            ></el-input>
          </el-form-item>
          <el-form-item
            label="APP图标"
            :label-width="formLabelWidth"
            prop="img"
          >
            <img
              class="upImg"
              prop="imgRules"
              style="width: 62px; height: 62px"
              :src="imgshow == 1 ? Rowcontent.AppImg : defaultimg"
              alt=""
            />
            <el-upload
              :show-file-list="false"
              :action="img_Url"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload"
            >
              <el-button style="width: 120px; margin: 11px 10px" type="primary">
                上传图片<i class="el-icon-upload el-icon--right"></i>
              </el-button>
            </el-upload>
            <p
              style="
                color: #f56c6c;
                font-size: 12px;
                line-height: 20px;
                margin-top: 5px;
              "
            >
              格式png、jpg;大小限制2M{{ isimg }}
            </p>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">返回</el-button>
          <el-button :disabled="isDisabled" @click="submitForm('editForm')">
            确定
          </el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    var numberRules = (rule, value, callback) => {
      if (/^[0-9]\d*$/.test(value)) {
        callback();
      } else {
        callback(new Error("请输入整数"));
      }
    };
    return {
      isimg: "",
      dialongtitle: "",
      dialogFormVisible: false,
      formLabelWidth: "120px",
      Rowcontent: {
        CategoryName: "",
        Description: "",
        SortNumber: "",
        ParentCategoryId: 0,
        AppImg: "",
      },
      rules: {
        CategoryName: [
          { required: true, message: "请完善信息", trigger: "blur" },
        ],
        // description: [
        //   { required: true, message: "请完善信息", trigger: "blur" },
        // ],
        // img: [{ required: true, message: "请完善信息", trigger: "blur" }],
        // parentTopicCategoryId: [
        //   { required: true, message: "请完善信息", trigger: "blur" },
        // ],
        SortNumber: [
          { required: true, message: "请完善信息", trigger: "blur" },
          { validator: numberRules, trigger: "blur" },
        ],
      },
      PageConfigure: [], //列表分页数据
      Params: {
        PageIndex: 1,
        PageSize: 10,
        TotalCount: 5,
        TotalPage: 0,
      },
      IsEdit: false,

      Alltopic: [{ value: -1, label: "全部" }],
      choosetopic: "全部", //话题选择
      Alltype: [], //获取所有分类
      //查询数据
      searchForm: {
        topicCategoryName: "",
        ParentCategoryId: -1,
      },
      releasetime: "", //发布时间
      parentValue: "",
      typeValue: "",
      searchContent: {},
      img_Url:
        "https://file.1renshi.com/FileHandler.ashx?module=article&type=image",
      defaultimg: require("../../assets/images/defaultimg.jpg"),
      imgshow: "1",
      //解决重复点击问题
      isDisabled: false,
      pickerOptions: {
        disabledDate: (time) => {
          return time.getTime() > Date.now();
        },
      },
    };
  },
  created() {
    this.searchDate();
    this.getalltype();
  },
  mounted() {},
  methods: {
    //刷新
    refresh() {
      this.$router.go(0);
    },

    //获取数据
    searchDate() {
      var _this = this;
      this.$get(_this.$api.GetHelpCenterCategoryPageList, { ..._this.Params })
        .then((res) => {
          _this.PageConfigure = res.Data;
          console.log(this.PageConfigure);
          console.log(res);
          _this.Params.TotalCount = res.TotalNumber;
          _this.Params.TotalPage = res.TotalPage;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    //获取所属分类
    getalltype() {
      var _this = this;
      this.$get(this.$api.GetAllTopHelpCenterCategor).then((res) => {
        console.log(res);
        for (let i = 0; i < res.Data.length; i++) {
          let a = res.Data[i].HelpCenterCategoryName;
          let b = res.Data[i].HelpCenterCategoryId;
          _this.Alltype.push({ label: a, value: a, id: b });
          _this.Alltopic.push({ label: a, value: b, id: b });
        }
      });
    },
    //话题选取
    Topicselect() {
      if (this.choosetopic !== -1) {
        this.searchForm.ParentCategoryId = this.choosetopic;
      } else {
        this.searchForm.ParentCategoryId = -1;
      }
      console.log(this.searchForm.ParentCategoryId);
    },

    //是否开启
    changeStatus(row, num) {
      this.$post(
        this.$api.ResetEnableFlag + `?CategoryId=${row.HelpCenterCategoryId}`
      ).then((res) => {
        if (res.Code == 200) {
          if (num == 1) {
            row.IsEnable = false;
            this.$message({
              message: "禁用成功",
              type: "success",
              duration: "1500",
            });
          } else {
            row.IsEnable = true;
            this.$message({
              message: "启用成功",
              type: "success",
              duration: "1500",
            });
          }
        }
      });
    },

    //新增
    addPage() {
      this.IsEdit = false;
      this.dialongtitle = "添加分类";
      this.dialogFormVisible = true;
      this.Rowcontent.CategoryName = "";
      this.Rowcontent.Description = "";
      this.Rowcontent.SortNumber = "";
      this.Rowcontent.ParentCategoryId = 0;
      this.typeValue = "";
      this.imgshow = "2";
      this.Rowcontent.AppImg = "";
      this.isimg = "(需要上传图片)";
    },
    //编辑
    eduit(val) {
      var _this = this;
      this.IsEdit = true;
      this.dialongtitle = "编辑分类";
      this.dialogFormVisible = true;
      this.imgshow = "1";
      this.Rowcontent.AppImg = val.Img;
      this.Rowcontent.CategoryName = val.HelpCenterCategoryName;
      this.Rowcontent.Description = val.Description;
      this.Rowcontent.SortNumber = val.SortNumber;
      this.Alltype.forEach(function (item, index) {
        if (val.ParentCategoryId == item.id) {
          _this.typeValue = item.value;
          // _this.Rowcontent.ParentCategoryId=item.id;
        }
        // console.log(val.ParentCategoryId)
        if (val.ParentCategoryId == 0) {
          _this.typeValue = "";
          _this.isimg = "(需要上传图片)";
        } else {
          _this.isimg = "";
        }
      });
      if(val.Img == null){
        _this.isimg = "(需要上传图片)";
      }else{
        _this.isimg = "";
      }
      this.Rowcontent.CategoryId = val.HelpCenterCategoryId;
      this.Rowcontent.ParentCategoryId = val.ParentCategoryId;
      this.Rowcontent.ParentCategoryName = this.typeValue;
    },

    //查询话题名是否重复
    // checkName(data) {
    //   var _this = this;
    //   this.$post(_this.$api.CheckTopicCategoryName, null, data).then((res) => {
    //     if (res.IsSuccess) {
    //       this.$message.error("话题名称已存在，请重新输入");
    //       this.Rowcontent.topicCategoryName = "";
    //     }
    //     console.log(data);
    //   });
    // },

    //新增、编辑的提交
    entime() {
      setTimeout(() => {
        this.isDisabled = false;
      }, 500);
    },
    submitForm(editForm) {
      var _this = this;
      if (this.isimg == "(需要上传图片)") {
        _this.$message({
          message: "请选择所属分类或者上传APP图标",
          type: "error",
          duration: "1500",
        });
        return;
      }
      this.isDisabled = true;
      this.entime();
      this.$refs[editForm].validate((valid) => {
        if (valid) {
          if (this.IsEdit) {
            console.log("编辑");
            this.$post(this.$api.EditCategory, null, this.Rowcontent)
              .then((res) => {
                if (res.Code == 200) {
                  _this.$message({
                    message: "编辑成功",
                    type: "success",
                    duration: "1500",
                  });
                  _this.resetForm();
                  _this.searchDate();
                }
              })
              .catch((err) => {
                console.log(err);
              });
            this.dialogFormVisible = false;
          } else {
            console.log("新增");
            // let data = { topicCategoryName: this.Rowcontent.topicCategoryName };
            this.$post(_this.$api.AddCategory, null, this.Rowcontent).then(
              (res) => {
                if (res.Code == 200) {
                  // this.$message.error("话题名称已存在，请重新输入");
                  // this.Rowcontent.topicCategoryName = "";
                  console.log(this.Rowcontent);
                  _this.$message({
                    message: "新增成功",
                    type: "success",
                    duration: "1500",
                  });
                  _this.getalltype();
                  _this.searchDate();
                  this.dialogFormVisible = false;
                }
              }
            );
          }
        } else {
          this.$message({
            message: "请完善信息",
            type: "warning",
            duration: "1500",
          });
        }
      });
    },

    //获取分页
    OnCurrentPageChanged(val) {
      this.Params.PageIndex = val;
      console.log(this.Params.PageIndex);
      this.keysSearch();
    },
    handleSizeChange(val) {
      this.Params.PageSize = val;
      console.log(val);
      this.keysSearch();
    },
    //图片上传
    handleAvatarSuccess(res, file) {
      this.imgshow = "1";
      console.log(res);
      this.Rowcontent.AppImg = res.success_list[0];
      if (this.Rowcontent.AppImg !== "") {
        this.isimg = "";
      }
    },
    beforeAvatarUpload(file) {
      var testmsg = file.name.substring(file.name.lastIndexOf(".") + 1);
      const extension = testmsg === "png";
      const extension2 = testmsg === "jpg";
      const extension3 = testmsg === "svg";
      const extension4 = testmsg === "jpeg";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!extension && !extension2 && !extension3 && !extension4) {
        this.$message({
          message: "APP图标只能是 png、jpg格式!",
          type: "warning",
          duration: "1500",
        });
        return false; //必须加上return false; 才能阻止
      }
      if (!isLt2M) {
        this.$message({
          message: "APP图标大小不能超过2M!请重新选择!",
          type: "warning",
          duration: "1500",
        });
        return false; //必须加上return false; 才能阻止
      }
      console.log(this.Rowcontent);
      console.log(this.Rowcontent.img);
    },
    //选择时间

    //编辑 上级分类
    seltype() {
      var _this = this;
      this.Alltype.forEach(function (item, index) {
        if (item.value == _this.typeValue) {
          _this.Rowcontent.ParentCategoryId = item.id;
          _this.Rowcontent.ParentCategoryName = item.value;
          if (_this.Rowcontent.ParentCategoryId == 0) {
            _this.isimg = "(需要上传图片)";
          } else {
            _this.isimg = "";
          }
        }
      });
    },
    //搜索 上级分类
    // selParent() {
    //   var _this = this;
    //   this.Alltype.forEach(function (item, index) {
    //     if (item.value == _this.parentValue) {
    //       _this.searchForm.ParentTopicCategoryId = item.id;
    //     }
    //   });
    // },
    //查询
    keysSearch(num) {
      var _this = this;
      if (
        _this.searchForm.topicCategoryName != null &&
        _this.searchForm.topicCategoryName.trim() != ""
      ) {
        _this.searchContent.KeyWords = this.searchForm.topicCategoryName.trim();
      }
      this.searchContent.ParentCategoryId = this.searchForm.ParentCategoryId;
      if (num == 1) {
        this.Params.PageIndex = 1;
      }
      this.$get(this.$api.GetHelpCenterCategoryPageList, {
        ...this.searchContent,
        ...this.Params,
      }).then((res) => {
        _this.PageConfigure = res.Data;
        _this.searchContent = {};
        // _this.searchContent.ParentCategoryId=-1;
        _this.Params.TotalCount = res.TotalNumber;
        _this.Params.TotalPage = res.TotalPage;
      });
    },
    //删除
    PreDelet(row, index, data) {
      let _this = this;
      this.$confirm("确认删除?删除后,数据将无法找回", "", {
        confirmButtonText: "确认",
        cancelButtonText: "取消",
        iconClass: "el-icon-question",
      })
        .then(() => {
          this.$post(
            this.$api.DeleteCategory + "?CategoryId=" + row.HelpCenterCategoryId
          )
            .then((res) => {
              if (res.Code == 200) {
                this.$message({
                  message: "删除成功",
                  type: "success",
                  duration: "1500",
                });
                _this.searchDate();
              }
            })
            .catch((err) => {
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //重置查询
    resetForm() {
      this.searchForm.topicCategoryName = "";
      // this.searchForm.ParentTopicCategoryId = -1;
      // this.searchForm.createTime = "";
      // this.parentValue = "";
      // this.releasetime = "";
      this.searchForm.ParentCategoryId = -1;
      this.choosetopic = "全部";
      this.Params.PageIndex = 1;
      this.searchDate();
    },
  },
};
</script>

<style lang="stylus" scoped>
/deep/.is-center.is-leaf {
  background: #e6e6e6;
  color: #303133;
}

/deep/.has-gutter tr {
  th {
    background: #e6e6e6;
    color: #303133;
  }

  td {
    text-align: center;
  }
}

.staff {
  background: #fff;
  padding: 10px 10px;
  border-radius: 10px;

  .searchForm {
    margin-left: 150px;

    .el-form-item {
      vertical-align: middle;
      margin-top: 13px;
      margin-right: 30px;
    }
  }

  .backSpan {
    padding: 0px 12px;
    cursor: pointer;
  }

  .left-top {
    margin-bottom: 10px;

    .el-divider--horizontal {
      margin: 10px 0;
    }

    button {
      margin-left: 10px;
    }
  }

  /deep/.el-table .cell {
    white-space: nowrap; /* 不换行 */
  }

  .el-table .cell span {
    overflow: hidden; /* 超出部分隐藏 */
    white-space: nowrap; /* 不换行 */
    text-overflow: ellipsis; /* 超出部分文字以...显示 */
  }

  .task-list-page {
    margin-top: 10px;
  }
}

/* 编辑框样式 */
/deep/.edit-form {
  width: 500px;

  .el-dialog__header {
    height: 50px;
    text-align: center;
    background-color: #409eff;

    .el-dialog__title {
      line-height: 0px;
      font-size: 22px;
      color: #fff;
    }

    .el-dialog__headerbtn {
      display: none;
    }
  }

  .el-form-item .el-textarea__inner {
    resize: none;
  }

  .el-dialog__body {
    padding-bottom: 5px !important;
  }

  .dialog-footer {
    text-align: center;

    .el-button {
      background-color: #fff;
      color: #606266;
      border: 1px solid #dcdfe6;
    }

    .el-button:hover {
      background-color: #409eff;
      border-color: #409eff;
      color: #fff;
    }
  }

  img.upImg {
    width: 40px;
    height: 40px;
    background-size: 100% 100%;
    float: left;
  }
}

// 小星星
.xiaoxx {
  color: #F56C6C;
  position: absolute;
  top: 0;
  left: -10px;
}
</style>